import * as React from 'react';

export const Footer: React.FC = () => {
  return (
    <header className="bg-white text-center py-8">
      <p className="text-sm text-gray-400">
        © 2020 Delaplumes. All Rights Reserved, by Urbanlink
      </p>
    </header>
  );
};
