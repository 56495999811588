import React from 'react';
import Helmet from 'react-helmet';

import { Header } from './Header';

import 'fontsource-montserrat';
import 'fontsource-montserrat/100.css';
import 'fontsource-montserrat/900.css';
import 'fontsource-lato';
import 'fontsource-lato/700.css';
import { Footer } from './Footer';

interface LayoutProps {
  title?: string;
}
export const Layout: React.FC<LayoutProps> = ({ children, title }) => {
  const pageTitle = title ? title + ' | Delaplumes' : 'Delaplumes';

  return (
    <div>
      <Helmet
        title={pageTitle}
        meta={[
          { name: 'description', content: 'Delaplumes' },
          { name: 'keywords', content: 'delaplumes' },
        ]}
      />
      <Header />
      <div className="px-4 md:p-0">{children}</div>
      <Footer />
    </div>
  );
};
