import * as React from 'react';
import Link from 'gatsby-link';
// import Helmet from 'react-helmet';

const menu = [
  {
    title: 'Sales',
    to: '/sales',
  },
  {
    title: 'Vision',
    to: '/vision',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
];
export const Header: React.FC = () => {
  return (
    <header className="bg-white text-center p-4">
      <h1 className="mb-0 text-lg leading-tight">
        <Link to="/" className="text-black hover:text-primary">
          DELAPLUMES
        </Link>
      </h1>
      <nav className="my-4">
        <ul className="m-0 p-0">
          {menu.map((item) => (
            <li
              className="text-sm p-2 hover:underline inline-block"
              key={item.title}
            >
              <Link to={item.to} activeClassName="underline text-black">
                {item.title}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  );
};
